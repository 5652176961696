import axios from 'axios'
import apiWrapper from './apiWrapper'
import { API_URL } from '../utils/constants'

const auth = async (mtoken: string) => {
  try {
    await apiWrapper()

    return await axios.post(`${API_URL}/api/auth/magic-signin`, { mtoken })
  } catch (e) {
    // @ts-ignore
    return e?.response
  }
}
export default auth
