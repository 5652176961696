import axios from 'axios'
import apiWrapper from './apiWrapper'
import { API_URL } from '../utils/constants'

const signOut = async () => {
  try {
    await apiWrapper()

    return await axios.post(`${API_URL}/api/auth/signout`)
  } catch (e) {
    // @ts-ignore
    return e?.response
  }
}
export default signOut
