import React from 'react'
import clsx from 'clsx'
import IconClose from '@/components/ui/Icon/IconClose'

export interface Props {
  type?: 'error' | 'success'
  className?: string
  showClose?: boolean
  onClose?: () => void
  children: React.ReactNode
}

const Alert: React.FC<Props> = ({
  type = 'error',
  className = '',
  showClose = false,
  onClose = () => {},
  children,
}) => (
  <div
    className={clsx(
      'rounded-md px-4 py-3 text-sm font-medium',
      type === 'error' && 'bg-red-50 text-red-600',
      type === 'success' && 'bg-green-50 text-green-600',
      className
    )}
  >
    <div className="flex items-center gap-2">
      {showClose && (
        <button type="button" onClick={onClose}>
          <IconClose />
        </button>
      )}
      {children}
    </div>
  </div>
)

export default Alert
