import React, { useMemo, useEffect } from 'react'
import { omit, pick } from 'lodash'
import { createPopup } from '@typeform/embed'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { APP_ROUTES, TYPEFORM } from 'utils/constants'
import { Listbox } from '@headlessui/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { Locale } from 'generated/graphcms'

import { logGoogleAnalyticEvent, logGoogleTagManagerEvent } from 'utils/tracking'
import Header from 'components/ui/Header/Header'
import Anchor from 'components/ui/Anchor/Anchor'
import ListboxButton from 'components/ui/Listbox/ListboxButton'
import ListboxOptions from 'components/ui/Listbox/ListboxOptions'
import ListboxOption from 'components/ui/Listbox/ListboxOption'
import Button from '@/components/ui/Button/Button'
import IconUser from '@/components/ui/Icon/IconUser'
import getUser from '../../../api/getUser'
import signOut from '../../../api/signOut'
import { user, company } from '../../../store/profile'
import '@typeform/embed/build/css/popup.css'
import convertKeysToCamelCase from '../../../utils/convertKeysToCamelCase'

interface Props {
  locationLinkSuffix?: string
}

const HeaderCombined: React.FC<Props> = ({ locationLinkSuffix = '' }) => {
  const router = useRouter()
  const { locale, pathname, asPath, query } = router

  const languages = Object.values(Locale) as Locale[]
  const { t } = useTranslation('common')

  const [userVariable, setUserVariable] = useRecoilState(user)
  const setCompanyVariable = useSetRecoilState(company)

  const switchLocaleTo = (newLocale: Locale) => {
    logGoogleTagManagerEvent('language_switch', { newLocale })
    document.cookie = `NEXT_LOCALE=${newLocale}; max-age=31536000; path=/`
    return router.push({ pathname, query }, asPath, { locale: newLocale })
  }

  const { open: openContactForm } = useMemo(
    () => createPopup(locale === Locale.De ? TYPEFORM.CONTACT_ID_DE : TYPEFORM.CONTACT_ID),
    [locale]
  )

  const { open: openPartnerForm } = useMemo(() => createPopup(TYPEFORM.BECOME_PARTNER_ID), [])

  const getInTouch = () => {
    logGoogleAnalyticEvent('free_consultation')
    openContactForm()
  }

  const fetchUser = async () => {
    if (!userVariable.requested) {
      try {
        const res = await getUser()

        if (res.data?.status === 'success' && res.data?.data?.user) {
          const authenticatedUser = res.data.data.user

          setUserVariable({
            locale: '',
            companyName: '',
            email: '',
            firstName: '',
            jobTitle: '',
            lastName: '',
            name: '',
            phone: '',
            loggedIn: true,
            requested: true,
            ...convertKeysToCamelCase(omit(authenticatedUser, 'customer')),
          })

          const customer = pick(authenticatedUser, 'customer')

          if (customer?.customer?.legal_address) {
            // @ts-ignore
            setCompanyVariable(convertKeysToCamelCase(customer?.customer?.legal_address))
          }
        } else {
          setUserVariable({
            ...userVariable,
            loggedIn: false,
            requested: true,
          })
        }
      } catch (e) {
        setUserVariable({
          ...userVariable,
          loggedIn: false,
          requested: true,
        })
      }
    }
  }

  useEffect(() => {
    fetchUser()
  }, [])

  const accountListHandler = async (type: 'profile' | 'dashboard' | 'logout') => {
    // eslint-disable-next-line default-case
    switch (type) {
      case 'profile':
        router.push(APP_ROUTES.PROFILE)
        break
      case 'dashboard':
        router.push(APP_ROUTES.DASHBOARD)
        break
      case 'logout':
        await signOut()

        router.push(APP_ROUTES.HOME)

        setUserVariable({
          companyName: '',
          email: '',
          firstName: '',
          jobTitle: '',
          lastName: '',
          locale: '',
          name: '',
          phone: '',
          loggedIn: false,
          requested: false,
        })
        localStorage.clear()
        sessionStorage.clear()
        break
    }
  }

  const navigation = () => (
    <div className="flex flex-col space-y-5 lg:flex-row lg:space-x-10 lg:space-y-0">
      <Link href={APP_ROUTES.VENUES + locationLinkSuffix} passHref legacyBehavior>
        <Anchor>{t('header.navigation.venues')}</Anchor>
      </Link>
      <Link href={APP_ROUTES.ABOUT} passHref legacyBehavior>
        <Anchor>{t('header.navigation.about')}</Anchor>
      </Link>
      <Link href={APP_ROUTES.BLOG} passHref legacyBehavior>
        <Anchor>{t('header.navigation.blog')}</Anchor>
      </Link>
    </div>
  )

  const extras = () => (
    <div className="flex flex-col space-y-5 text-sm lg:flex-row lg:items-center lg:space-x-8 lg:space-y-0">
      <div className="flex flex-col space-y-5 lg:flex-row lg:items-center lg:space-x-6 lg:space-y-0">
        <Button onClick={openPartnerForm} variant="tertiary">
          {t('header.venueContactButton')}
        </Button>
        <Button onClick={getInTouch}>{t('header.consultationButton')}</Button>
        <div className="inline-flex">
          <Listbox as="div" className="relative" value={locale} onChange={switchLocaleTo}>
            {({ open }) => (
              <>
                <ListboxButton variant="secondary" open={open}>
                  {t(`language.${locale}`)}
                </ListboxButton>
                <ListboxOptions>
                  {languages.map((language) => (
                    <ListboxOption key={language} value={language}>
                      {t(`language.${language}`)}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </>
            )}
          </Listbox>
        </div>
      </div>
      {userVariable.loggedIn ? (
        <Listbox as="div" className="relative" onChange={accountListHandler}>
          {({ open }) => (
            <>
              <ListboxButton variant="secondary" open={open}>
                <div className="flex items-center gap-1">
                  <IconUser />
                  {userVariable.name !== userVariable.email
                    ? userVariable.firstName
                    : t('auth.account')}
                </div>
              </ListboxButton>
              <ListboxOptions>
                <ListboxOption value="profile">{t('profile.profileTitle')}</ListboxOption>
                <ListboxOption value="dashboard">{t('dashboard.dashboardTitle')}</ListboxOption>
                <ListboxOption value="logout">{t('auth.logOutButton')}</ListboxOption>
              </ListboxOptions>
            </>
          )}
        </Listbox>
      ) : (
        pathname !== APP_ROUTES.AUTHENTICATION && (
          <button onClick={() => router.push(APP_ROUTES.AUTHENTICATION)} type="button">
            {t('auth.signUpLogIn')}
          </button>
        )
      )}
    </div>
  )

  return <Header navigation={navigation} extras={extras} />
}

export default HeaderCombined
