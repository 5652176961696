import getCSRF from './getCSRF'

const axiosWrapper = async () => {
  const XSRF_TOKEN_COOKIE = document.cookie
    .split('; ')
    .find((row) => row.startsWith('XSRF-TOKEN='))
    ?.split('=')[1]

  if (!XSRF_TOKEN_COOKIE) {
    await getCSRF()
  }
}
export default axiosWrapper
